import "./page-template.scss";

//load styles
//add classes with unique names
[...document.getElementsByClassName("main-content")].forEach((item) => {
  item.classList.add("main_content");
});

function addLoader() {
  document.getElementById("js__page_loader").classList.add("loading");
}

function addWidget() {
  $(() => {
    [...document.getElementsByClassName("sw-ff-layout")].forEach((item) => {
      item.classList.add("sw_ff_layout");
    });
  });
}

function setPageTemplate() {
  addLoader();
  addWidget();
}

function hideLoader(hide = true) {
  if (hide) {
    $("#js__page_loader").hide();
  } else {
    $("#js__page_loader").show();
  }
}

const methods = {
  setPageTemplate,
  hideLoader: (hide) => {
    hideLoader(hide);
  }
};

export default methods;
