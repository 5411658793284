import { detectOverflow } from "@popperjs/core";
import { drop } from "lodash";
import "./coffee.main.scss";

const base_url = "https://lnbpback.truewisdom.co";

/* create title page */
function createSectionTitle(title_text, container) {
  let title_container = document.createElement("div");
  title_container.classList.add("container", "clearfix");

  let dot = document.createElement("span");
  dot.classList.add("dot");

  title_container.appendChild(dot);

  let title = document.createElement("div");
  title.classList.add("title");
  title.innerHTML = title_text;

  title_container.appendChild(title);

  container.appendChild(title_container);
}

function createTextInput(
  label_text,
  place_holder,
  input_name,
  input_type,
  input_id,
  enabled = true,
  default_value = "",
  visible = true,
  styles = []
) {
  let container = document.createElement("div");
  container.id = "js__container_" + input_id;
  container.classList.add("form-floating", "row", ...styles);

  let col = document.createElement("div");
  col.classList.add("col-lg-12", "col-md-6", "col-sm-6");

  let input = document.createElement("input");
  input.type = input_type;
  if (input_type == "file") {
    input.classList.add("form-control", "g-2");
  } else {
    input.classList.add("form-control", "g-1");
  }
  input.id = input_id;
  input.placeholder = place_holder;
  input.name = input_name;
  input.disabled = !enabled;
  input.value = default_value;
  input.setAttribute("aria-describedby", "error_message_" + input_id);

  let error_message = document.createElement("span");
  error_message.classList.add("error_message");
  error_message.style.color = "red";
  error_message.style.marginTop = "1rem";
  error_message.style.marginRight = "0";
  error_message.id = "error_message_" + input_id;

  let label = document.createElement("label");
  label.classList.add("g-1");
  label.setAttribute("for", input_name);
  label.innerText = label_text;

  container.appendChild(input);
  container.appendChild(label);
  container.appendChild(error_message);

  if (visible == false) {
    container.style.display = "none";
  }

  return col.appendChild(container);
}

function createOptionList(
  label_text,
  input_name,
  input_id,
  option_list = [{ text: "Selecciona uno", value: -1 }],
  visible = true
) {
  let container = document.createElement("div");
  container.id = "js__container_" + input_id;

  container.classList.add("form-floating", "row");

  let col = document.createElement("div");
  col.classList.add("col-lg-12", "col-md-6", "col-sm-6");

  let input = document.createElement("select");
  input.classList.add("form-control", "g-1");
  input.id = input_id;
  input.name = input_name;
  input.setAttribute("aria-describedby", "error_message_" + input_id);

  let error_message = document.createElement("span");
  error_message.style.color = "red";
  error_message.style.marginTop = "1rem";
  error_message.style.marginRight = "0";
  error_message.id = "error_message_" + input_id;

  option_list.forEach((element) => {
    let option = document.createElement("option");
    option.value = element.value;
    option.innerText = element.text;

    input.appendChild(option);
  });

  let label = document.createElement("label");
  label.classList.add("g-1");
  label.setAttribute("for", input_name);
  label.innerText = label_text;

  container.appendChild(input);
  container.appendChild(label);
  container.appendChild(error_message);

  if (visible == false) {
    container.style.display = "none";
  }

  return col.appendChild(container);
}

function showRotateBanner(id_section) {
  //Prepare the request data.
  var ajax_data = {
    id_section: id_section,
    count_banners: 1,
    url_link: 1,
    id_device: 1
  };

  let image_container = document.createElement("a");
  image_container.style.width = "100%";
  image_container.style.display = "block";
  image_container.classList.add("text-center", "banner_center");

  $.ajax({
    type: "POST",
    url: base_url + "/analytics/banners/view",
    data: ajax_data
  }).done(function (response) {
    console.log(
      "this is the response on coffee main rotate banner",
      response.items[0].item
    );
    if (
      response.items[0].item.full_url_image != null &&
      !response.items[0].item.full_url_image.includes("no-image.jpg")
    ) {
      image_container.href = response.items[0].item.url_link;
      let image = document.createElement("img");
      image.src = base_url + response.items[0].item.full_url_image;
      image_container.appendChild(image);
    }
  });

  return image_container;
}

const methods = {
  createSectionTitle: (title, container) => {
    createSectionTitle(title, container);
  },
  createTextInput: (
    label_text,
    place_holder,
    input_name,
    input_type,
    input_id,
    enabled,
    default_value,
    visible,
    styles
  ) =>
    createTextInput(
      label_text,
      place_holder,
      input_name,
      input_type,
      input_id,
      enabled,
      default_value,
      visible,
      styles
    ),
  createOptionList: (label_text, input_name, input_id, option_list, visible) =>
    createOptionList(label_text, input_name, input_id, option_list, visible),
  base_url: base_url,
  id_season: 32,
  showRotateBanner: (id_section) => showRotateBanner(id_section)
};

export default methods;
