import "./header.scss";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function setNavBackgroundColor() {
  //get the top visible header
  const menu_top_nav_bar = $("#js__header_header");

  //add classes with unique names
  [...document.getElementsByClassName("navbar-header")].forEach((item) => {
    item.classList.add("navbar_header");
  });

  document
    .getElementById("js__header_offcanvas-header")
    .classList.add("offcanvas_header");

  [...document.getElementsByClassName("offcanvas-body")].forEach((item) => {
    item.classList.add("offcanvas_body");
  });
  [...document.getElementsByClassName("nav-link active")].forEach((item) => {
    item.classList.add("active");
  });
  [...document.getElementsByClassName("dropdown-menu")].forEach((item) => {
    item.classList.add("dropdown_menu");
  });
  [...document.getElementsByClassName("nav-link")].forEach((item) => {
    item.classList.add("nav_link");
  });
  [...document.getElementsByClassName("nav_social_network")].forEach((item) => {
    item.classList.add("nav_social_network");
  });
  [...document.getElementsByClassName("nav_social_network_img")].forEach(
    (item) => {
      item.classList.add("nav_social_network_img");
    }
  );

  menu_top_nav_bar.addClass("menu_top_nav_bar");

  menu_top_nav_bar.show();
}

function setPageTemplate() {
  setNavBackgroundColor();
}

const methods = {
  setPageTemplate
};

export default methods;
